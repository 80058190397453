<!-- commonDlg.vue -->
<template>
  <v-dialog :model-value="dlgVisible" max-width="500" persistent>
    <v-card>
      <v-card-title>
        <span>{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <slot />
      </v-card-text>

      <v-card-actions class="my-2 d-flex justify-center align-item-center">
        <v-btn
          class="text-none mr-4"
          rounded="xl"
          :text="_cancelBtn.name"
          @click="_cancelBtn.action"
        />

        <v-btn
          class="text-none ml-4"
          color="blue-darken-1"
          rounded="xl"
          :text="_confirmBtn.name"
          variant="flat"
          @click="_confirmBtn.action"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>

const emit = defineEmits(['update:dlgVisible', 'closeDlg'])
const props = defineProps({
  dlgVisible: Boolean,
  title: {
    type: String,
    default: () => '標題'
  },
  cancelBtn: {
    type: Object,
    default: () => ({
      name: '取消'
    })
  },
  confirmBtn: {
    type: Object,
    default: () => ({
      name: '確定'
    })
  }
})

const _cancelBtn = ref({
  name: props.cancelBtn.name,
  action: () => {
    if (props.cancelBtn && props.cancelBtn.action) {
      props.cancelBtn.action()
    } else {
      emit('update:dlgVisible', false)
    }
  }
})

const _confirmBtn = ref({
  name: props.confirmBtn.name,
  action: () => {
    if (props.confirmBtn.action) {
      props.confirmBtn.action()
    } else {
      emit('update:dlgVisible', false)
    }
  }
})

</script>
