<template>
  <CommonDlg v-model:dlgVisible="visible" :cancel-btn="_cancelBtn" :confirm-btn="_confirmBtn" :title="title">
    <v-card-text>
      <div class="text-center">
        <v-icon class="fa-solid fa-exclamation-triangle text-warning" size="50" />

        <div class="text-h6 text-medium-emphasis ps-2 font-weight-bold mt-4">
          {{ computedSubTitleFirst }}
          &nbsp;<span class='linkColor'>{{ computedSubTitleObjectName }}</span>&nbsp;
          {{ computedSubTitleSecond }}
        </div>
        <p class="mt-4">
          {{ computedContent }}
        </p>
      </div>
    </v-card-text>
  </CommonDlg>
</template>

<script setup>
const { t } = useI18n()

const emit = defineEmits(['update:dlgVisible', 'deleteFn'])

const _cancelBtn = {
  name: t('common.cancel'), // 取消
  action: () => {
    emit('update:dlgVisible', false)
  }
}

const _confirmBtn = {
  name: t('common.confirm'), // 確定
  action: () =>
    emit('deleteFn', props.data)
}

const visible = ref(false)

const props = defineProps({
  dlgVisible: Boolean,
  data: {
    type: Object,
    default: () => {}
  },
  title: {
    type: String,
    default: ''
  },
  content: {
    type: String,
    default: ''
  },
  subTitleFirst: {
    type: String,
    default: ''
  },
  subTitleSecond: {
    type: String,
    default: ''
  },
  subTitleObjectName: {
    type: String,
    default: ''
  }
})

const computedContent = computed(() => props.content || t('component.dlg.deleteDefaultText')) // 確定要刪除此筆資料嗎？


const computedSubTitleFirst = computed(() => props.subTitleFirst || t('component.dlg.deleteDefaultTitle')) // 刪除資料?
const computedSubTitleSecond = computed(() => props.subTitleSecond || '') 
const computedSubTitleObjectName = computed(() => props.subTitleObjectName || '')



watch(() => props.dlgVisible, (val) => {
  visible.value = val
})
</script>
